import React, { useState } from "react";
// import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby";

import AgeCalculator from "../components/agegate-component";
import Footer from "./footer/footer";
import LanguageSwitcher from "./header/languageSwitcher";

import snickersLogo from "../images/snickers-hunger-insurance.png";

const AgeGate = (props) => {
  const [isOldError, setIsOldError] = useState(false);
  const [ageError, setAgeError] = useState(false);
  return (
    <>
      <section className="agegate-hldr d-flex justify-content-between flex-column align-items-center pt-5">
        <div className="d-flex flex-column justify-content-center align-items-center h-100">
          <LanguageSwitcher />
          <Link to="/en">
            <figure className="logo">
              {/* <StaticImage
                src="../images/snickers-hunger-insurance.png"
                alt="Snickers Logo"
                quality={100}
              /> */}
              <img
                src={snickersLogo}
                alt="Snickers Logo"
                className="img-fluid"
              />
            </figure>
          </Link>
          <h1 className="text-white text-uppercase">
            To get insured submit your Age
          </h1>
          <AgeCalculator
            setIsOldError={setIsOldError}
            setAgeError={setAgeError}
            setAgeGateShow={props.setAgeGateShow}
          />
          {isOldError && (
            <p className="text-center input-error mb-0">
              {/* You are not Eligible to Enter into Site! */}
              Oops, please complete your details then press enter.
            </p>
          )}
          {ageError && (
            <p className="text-center input-error mb-0">
              {/* You are not Eligible to Enter into Site! */}
              Sorry you aren't allowed to enter this site.
            </p>
          )}
        </div>
        <Footer />
      </section>
    </>
  );
};

export default AgeGate;
